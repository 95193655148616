.dd-m-form {
  &-text {
    padding-top: rem(6px);
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: rem(14px);
    line-height: rem(24px);
    letter-spacing: 0.01em;
    color: var(--color-gray-03);
    text-align: center;

    .uil {
      font-size: rem(18px);
      margin-right: rem(4px);
      vertical-align: bottom;
    }
  }

  &-footer {
    margin-top: rem(20px);

    @media screen and (min-width: $size-desktop) {
      margin-top: rem(28px);
    }
  }

  .has-error {
    input {
      background-color: var(--color-assertive-02) !important;
      border-color: var(--color-assertive) !important;
    }

    .input-control,
    .input-control ~ small {
      margin-bottom: 0 !important;
    }

    span {
      display: block;

      font-family: $font-secondary;
      font-size: rem(12px);
      line-height: rem(16px);

      text-align: right;
      color: var(--color-assertive);

      margin-top: rem(4px);
      margin-bottom: rem(24px);
    }
  }
}
