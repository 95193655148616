.dd-m-box {
  padding: rem(24px);

  @media screen and (min-width: $size-desktop) {
    min-width: rem(528px);
    max-width: rem(528px);
    border-radius: rem(8px);
    padding: rem(64px) rem(72px);
  }

  &-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    margin: 0 0 rem(42px);
    color: var(--color-primary-09);
    text-align: left;

    @media screen and (min-width: $size-desktop) {
      font-size: rem(24px);
      line-height: rem(32px);
      text-align: center;
      margin: 0 0 rem(32px);
    }

    span {
      @media screen and (max-width: $size-desktop - 1) {
        display: block;
      }
    }
  }
}
