/**
 * Colors
 *
 * @description
 * Use as described below:
 * {
 *  color: var(--color-primary);
 * }
 */

:root {
  --color-primary: #00375c;
  --color-primary-tint: #0099ff;
  --color-primary-tint-rgb: 0, 153, 255;
  --color-primary-02: #0068ad;
  --color-primary-03: #7acaff;
  --color-primary-04: #a3daff;
  --color-primary-05: #b8e2ff;
  --color-primary-06: #52baff;
  --color-primary-07: #29a9ff;
  --color-primary-08: #0081d6;
  --color-primary-09: #00498a;
  --color-primary-10: #f4fbff;

  --color-secondary: #00abb8;
  --color-secondary-02: #00858f;
  --color-secondary-03: #00d0e0;
  --color-secondary-04: #2ed8e6;
  --color-secondary-04-rgb: 46 216 230;
  --color-secondary-05: #5ce1eb;
  --color-secondary-06: #8ae9f1;
  --color-secondary-07: #00abb8;

  --color-assertive: #e84242;
  --color-assertive-02: #fff0f0;

  --color-gray: #343a40;
  --color-gray-02: #495057;
  --color-gray-03: #727980;
  --color-gray-04: #adb5bd;
  --color-gray-05: #ced4da;
  --color-gray-06: #dee2e6;
  --color-gray-06-rgb: 222 226 230;
  --color-gray-07: #e9ecef;
  --color-gray-08: #f3f5f7;
  --color-gray-08-rgb: 243, 245, 247;

  --color-auxiliary: #f36138;
  --color-auxiliary-02: #ffffff;
  --color-auxiliary-02-rgb: 255, 255, 255;
}
