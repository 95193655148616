.dd-m-animation-load {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, var(--color-gray-07), #E8E8E8 50%, var(--color-gray-07));
  animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}
