@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon/icomoon.eot?3sxbnm');
  src: url('/assets/fonts/icomoon/icomoon.eot?3sxbnm#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/icomoon/icomoon.ttf?3sxbnm') format('truetype'),
    url('/assets/fonts/icomoon/icomoon.woff?3sxbnm') format('woff'),
    url('/assets/fonts/icomoon/icomoon.svg?3sxbnm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-heart-checkmark:before {
  content: '\e910';
}
.icon-info-circle:before {
  content: '\e90f';
}
.icon-phone_sound:before {
  content: '\e90e';
}
.icon-hand-colorful .path1:before {
  content: '\e905';
  color: rgb(208, 225, 246);
}
.icon-hand-colorful .path2:before {
  content: '\e906';
  margin-left: -1.07421875em;
  color: rgb(13, 49, 111);
}
.icon-hand-colorful .path3:before {
  content: '\e907';
  margin-left: -1.07421875em;
  color: rgb(13, 49, 111);
}
.icon-hand-colorful .path4:before {
  content: '\e908';
  margin-left: -1.07421875em;
  color: rgb(208, 225, 246);
}
.icon-hand-colorful .path5:before {
  content: '\e909';
  margin-left: -1.07421875em;
  color: rgb(76, 208, 116);
}
.icon-hand-colorful .path6:before {
  content: '\e90a';
  margin-left: -1.07421875em;
  color: rgb(13, 49, 111);
}
.icon-hand-colorful .path7:before {
  content: '\e90b';
  margin-left: -1.07421875em;
  color: rgb(76, 208, 116);
}
.icon-hand-colorful .path8:before {
  content: '\e90c';
  margin-left: -1.07421875em;
  color: rgb(13, 49, 111);
}
.icon-hand:before {
  content: '\e90d';
}
.icon-add:before {
  content: '\e901';
}
.icon-dashboard:before {
  content: '\e902';
}
.icon-statement:before {
  content: '\e903';
}
.icon-video:before {
  content: '\e904';
}
.icon-sale:before {
  content: '\e900';
}
