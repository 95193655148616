.dd-m-space-bottom {
  margin-bottom: rem(32px);

  &.sm {
    margin-bottom: rem(16px);
  }
}

.dd-m-space-bottom-big {
  margin-bottom: rem(32px);

  @media screen and (min-width: $size-desktop) {
    margin-bottom: rem(52px);
  }
}

.dd-m-space-top {
  margin-top: rem(24px);

  @media screen and (min-width: $size-desktop) {
    margin-top: rem(32px);
  }
}
