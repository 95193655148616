.dd-m-button {
  border-radius: rem(4px);
  padding: rem(12px) rem(24px);
  font-family: $font-secondary;
  font-style: normal;
  text-align: center;
  letter-spacing: -0.01em;
  display: inline-block;
  font-weight: 500;
  font-size: rem(13px);
  line-height: rem(16px);
  transition: all 0.2s ease;

  &.full {
    width: 100%;
  }

  &.big {
    padding: rem(16px);
    font-weight: 600;
    font-size: rem(17px);
    line-height: rem(24px);
    border-radius: rem(8px);
    max-height: rem(56px);
  }

  &.clear {
    padding: 0;
    border-radius: 0;
    background: transparent;
    font-size: rem(13px);
    line-height: 130%;

    &:hover {
      background: transparent;
    }
  }

  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;

    & > span {
      display: none;
    }
  }

  &-primary {
    @extend .dd-m-button;
    background: var(--color-primary-02);
    color: var(--color-auxiliary-02);

    &:hover {
      background-color: var(--color-primary);
    }

    &.clear {
      color: var(--color-primary-tint);

      &:hover {
        color: var(--color-primary-03);
      }
    }
  }

  &-secondary {
    @extend .dd-m-button;
    background: var(--color-primary-tint);
    color: var(--color-auxiliary-02);

    &:hover {
      background-color: var(--color-primary-08);
    }

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }

    &.outline {
      background: var(--color-auxiliary-02);
      color: var(--color-primary-tint);
      border: rem(1px) solid var(--color-primary-tint);

      &:not(.no-responsive) {
        @media screen and (max-width: $size-desktop - 1) {
          padding: 0;
          border: 0;
        }
      }

      &:hover {
        background: var(--color-primary-08);
        color: var(--color-auxiliary-02);
      }

      &[disabled] {
        border-color: var(--color-primary-05);
        color: var(--color-primary-05);
      }
    }

    &.clear {
      color: var(--color-primary-tint);

      &:hover {
        color: var(--color-primary-08);
      }
    }
  }

  &-outline-icon {
    position: absolute;
    top: rem(38px);
    right: rem(22px);

    padding: 0;

    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 0;
    line-height: rem(16px);
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: var(--color-gray-04);

    @media screen and (min-width: $size-tablet) {
      top: rem(36px);
      right: rem(32px);
      font-size: rem(12px);
    }

    i {
      font-size: rem(24px);
      vertical-align: middle;
    }
  }

  &-outline-icon-sub {
    background-color: var(--color-auxiliary-02);
    border: 1px solid var(--color-primary-04);
    border-radius: rem(4px);
    padding: rem(12px) rem(24px);
    display: inline-block;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: rem(13px);
    line-height: rem(24px);
    color: var(--color-primary-tint);

    transition: background-color 0.2s ease;

    @media screen and (min-width: $size-tablet) {
      padding: rem(16px) rem(24px);
    }

    &:hover {
      background-color: rgba(148, 210, 253, 0.5);
    }

    i {
      color: var(--color-primary-03);
      font-size: rem(20px);
      vertical-align: top;
      margin-right: rem(8px);
    }
  }
}
