.dd-l-container {
  min-height: 100%;

  @media screen and (min-width: $size-desktop) {
    display: flex;
  }

  &-sidebar {
    position: relative;
    display: none;

    @media screen and (min-width: $size-desktop) {
      display: block;
      flex: 0 0 rem(400px);
      background: var(--color-primary-09);
      color: var(--color-auxiliary-02);
    }

    &-gt {
      display: none;

      @media screen and (min-width: $size-desktop) {
        display: block;
      }
    }
  }

  &-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    pointer-events: none;

    width: auto;
    height: auto;
  }

  &-logo {
    display: block;
    margin: rem(32px) rem(24px) rem(66px);

    @media screen and (min-width: $size-desktop) {
      margin: rem(56px) auto rem(184px);
    }

    @media screen and (min-width: $size-desktop) and (max-height: rem($size-tablet)) {
      margin: rem(32px) auto rem(24px);
    }
  }

  &-logo--pet {
    margin: rem(16px) rem(24px) rem(48px);

    @media screen and (min-width: $size-desktop) {
      margin: rem(32px) auto rem(142px);
    }

    @media screen and (min-width: $size-desktop) and (max-height: rem($size-tablet)) {
      margin: rem(32px) auto rem(24px);
    }
  }

  &-title {
    position: relative;
    z-index: 9;
    font-weight: 500;
    font-size: rem(40px);
    line-height: 120%;
    text-align: left;
    letter-spacing: -0.02em;
    margin: 0 0 0 84px;

    strong {
      font-weight: 700;
    }
  }

  &-content {
    align-items: center;
    justify-content: center;
    flex: 1;

    @media screen and (min-width: $size-desktop) {
      display: inherit;
    }
  }

  &-space {
    padding: rem(24px);

    @media screen and (min-width: $size-desktop) {
      padding: rem(32px);
    }
  }

  &-scroll {
    padding-top: rem(64px);
    padding-bottom: rem(64px);

    @media screen and (min-width: $size-tablet) {
      max-height: calc(100vh - 64px);
      overflow: auto;
      padding-top: 0;
      padding-bottom: 0;
    }

    @media screen and (min-width: $size-desktop) {
      max-height: calc(100vh - 80px);
    }
  }
}
