@import 'animations';

.dd-m-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  th,
  td {
    position: relative;
    text-align: left;
    font-family: $font-secondary;
    font-style: normal;

    @media screen and (min-width: $size-tablet) {
      text-align: center;
    }
  }

  th:first-child,
  tr td:first-child {
    @media screen and (max-width: $size-tablet) {
      padding-left: rem(24px);
      padding-right: rem(16px);
    }
  }

  th:last-child,
  tr td:last-child {
    @media screen and (max-width: $size-tablet) {
      padding-right: rem(24px);
      padding-left: rem(20px);
    }
  }

  th {
    background-color: var(--color-primary-09);
    color: var(--color-auxiliary-02);
    font-weight: 600;
    font-size: rem(11px);
    line-height: 130%;
    height: rem(32px);

    &:first-child {
      border-radius: rem(2px) 0 0 rem(2px);
    }

    &:last-child {
      border-radius: 0 rem(2px) rem(2px) 0;
    }

    .icon {
      font-size: rem(16px);
      color: var(--color-auxiliary-02);
      padding: 0;
      vertical-align: middle;

      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  td {
    font-weight: 500;
    font-size: rem(12px);
    color: var(--color-gray-03);

    padding: rem(10px) 0;
    height: rem(55px);
    border-bottom: rem(1px) solid var(--color-gray-08);

    strong {
      font-weight: bold;
      color: var(--color-primary-08);
    }
  }

  .nowrap {
    white-space: nowrap;
  }

  .align-left {
    text-align: left;
  }

  &-placeholder {
    position: relative;
    overflow: hidden;

    display: inline-block;

    width: 100%;
    max-width: 90%;
    height: rem(16px);

    background-color: var(--color-gray-07);
    border-radius: rem(4px);

    &:before {
      @extend .dd-m-animation-load;
    }
  }
}
