.dd-m-p-w-max {
  position: relative;

  max-width: rem(496px);
  background-color: var(--color-auxiliary-02);
  min-height: calc(100vh - 128px);
  max-height: calc(100vh - 128px);

  display: flex;
  flex-direction: column;

  @media screen and (min-width: $size-tablet) {
    min-height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
  }

  @media print {
    position: absolute;
    top: 0;
    min-height: 0;
    max-width: none;
    height: 100vh;
    width: 100%;
  }
}
