.dd-m-backdrop {
  transition: all .2s ease;

  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  pointer-events: none;

  &.active {
    @media screen and (min-width: $size-tablet) {
      background: rgb(0 0 0 / 40%);
      pointer-events: auto;
    }
  }
}
