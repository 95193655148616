@import './datepicker';
@import './dialogs';
@import './tooltips';

::-webkit-scrollbar {
  display: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

p,
html,
body {
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
  color-adjust: exact !important;
  font-size: 16px;
}

body {
  height: 100%;
  font-family: $font-main;
  font-size: rem($font-size);
  line-height: normal;
  cursor: default;
}

a,
button:not(:disabled) {
  cursor: pointer;
}

button {
  border: none;
  background: none;
  outline: none;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

app-alert {
  display: block;
}

// Graph tooltip
.ngx-charts-outer,
.ngx-charts {
  width: 100% !important;
  float: none !important;
}

.ngx-charts-tooltip-content {
  justify-content: center;
  background: var(--color-primary) !important;
  border-radius: rem(3px);
  padding: rem(16px) rem(24px) !important;

  font-family: $font-secondary;
  font-style: normal;
  font-weight: 500 !important;
  font-size: rem(11px);
  line-height: 130%;

  .title {
    display: block;
    font-size: rem(13px);
    margin-bottom: rem(1px);
  }

  .description {
    opacity: 0.4;
  }

  .position-right {
    border-right-color: var(--color-primary) !important;
  }

  .position-top {
    border-top-color: var(--color-primary) !important;
  }
}

// ngx-toastr
.ngx-toastr {
  background: var(--color-gray-08) !important;
  color: var(--color-gray-03) !important;
  font-family: $font-secondary;
  font-style: normal;
  font-weight: normal;
  font-size: rem(11px);
  line-height: rem(16px);
  border: rem(1px) solid var(--color-gray-07);
  box-shadow: none !important;
  border-radius: rem(3px) !important;
  min-height: rem(56px);
  display: flex;
  align-items: center;

  padding-left: rem(20px) !important;
  padding-right: rem(30px) !important;

  border-left: rem(4px) solid;

  &.toast-success {
    border-left-color: var(--color-secondary-02);

    .toast-message:before {
      content: '\e9c2';
      background-color: var(--color-secondary-02);
    }
  }

  &.toast-error {
    border-left-color: var(--color-auxiliary);

    .toast-message:before {
      content: '\e9b1';
      background-color: var(--color-auxiliary);
    }
  }
}

.toast-close-button {
  color: var(--color-gray-04) !important;

  float: none !important;
  position: absolute !important;
  right: 0 !important;
  top: 50% !important;
  transform: translate(-50%, -50%);

  span {
    font-family: 'unicons-line';
    font-size: 0;

    &:before {
      content: '\e9b8';
      font-size: rem(16px);
    }
  }
}

.toast-message:before {
  font-family: 'unicons-line';
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-auxiliary-02);
  border-radius: 50%;
  font-size: 16px;
  vertical-align: middle;
  margin-right: 8px;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.tour-step.arrow:before {
  content: none !important;
}
