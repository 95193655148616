.dd-l-row {
  @media screen and (min-width: $size-tablet) {
    display: flex;
    margin-left: rem(-16px);
    margin-right: rem(-16px);
    flex-wrap: wrap;
  }
}

.dd-l-col {
  flex: 1;
  padding: 0 0 rem(16px);

  @media screen and (min-width: $size-tablet) {
    padding: 0 rem(16px);
  }
}
