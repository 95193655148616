.selected:before {
  background-color: var(--color-primary-02);
  height: rem(40px);
  width: rem(40px);

  @media (min-width: $size-desktop-large) {
    height: rem(28px);
    width: rem(28px);
  }
}

.datepicker {
  height: 100%;
  max-width: rem(370px);
  width: 100%;
  min-width: 0 !important;
  z-index: 1;
  background: var(--color-auxiliary-02);
  position: relative;
  border: none;

  @media (min-width: $size-desktop-large) {
    max-width: rem(370px);
    margin: 0;
  }
}

.datepicker__content {
  border: none !important;
  padding: rem(48px) rem(32px);

  @media (min-width: $size-desktop-large) {
    padding: rem(24px) rem(22px);
  }
}

.datepicker__days {
  color: var(--color-gray-02);
  font-family: $font-secondary;
  font-size: rem(16px);
  font-style: normal;
  font-weight: normal;
  line-height: rem(18px);

  @media (min-width: $size-desktop-large) {
    font-size: rem(12px);
    line-height: rem(16px);
  }
}

.datepicker__days li:not(:last-child) {
  margin-bottom: rem(36px) !important;

  @media (min-width: $size-desktop-large) {
    margin-bottom: rem(16px) !important;
  }
}

.datepicker__week strong {
  color: var(--color-gray-02);
  font-family: $font-secondary;
  font-size: rem(14px);
  font-style: normal;
  font-weight: $font-semi;
  line-height: rem(16px);
  margin-bottom: rem(32px) !important;
  text-align: center;
}

.datepicker__weeks {
  @media (min-width: $size-desktop-large) {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.datepicker__header .previous,
.datepicker__header .next {
  display: none;
}

.datepicker__header > div:not(:empty) {
  width: 100%;
}
