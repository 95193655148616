.dd-m-title {
  font-weight: bold;
  font-size: rem(18px);
  line-height: rem(24px);
  color: var(--color-primary-08);
  margin: 0;

  i {
    font-size: rem(24px);
    margin-right: rem(10px);
    color: var(--color-primary-03);
    vertical-align: bottom;
  }

  &.mobile {
    margin-bottom: rem(24px);

    @media screen and (min-width: $size-tablet) {
      display: none;
    }
  }

  &.tablet {
    @media screen and (max-width: $size-tablet - 1) {
      display: none;
    }
  }

  &.has-badge {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      width: rem(3px);
      height: rem(24px);
      background-color: var(--color-primary-02);
      left: rem(-32px);
    }
  }
}
