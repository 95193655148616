.mdc-tooltip__surface {
  --mdc-plain-tooltip-supporting-text-size: 14px;
  --mdc-plain-tooltip-supporting-text-line-height: 18px;
  --mdc-plain-tooltip-supporting-text-font: $font-secondary;
  --mdc-plain-tooltip-supporting-text-weight: 500;
  --mdc-plain-tooltip-supporting-text-tracking: 0;

  max-width: 250px !important;
  padding: 20px !important;

  background-color: var(--color-primary-08) !important;

  overflow: visible !important;
}

.mdc-tooltip__surface:before {
  content: '' !important;
  position: absolute !important;
  left: -5px !important;
  top: 50% !important;

  width: 10px !important;
  height: 10px !important;

  background-color: inherit !important;
  border-radius: 0 !important;

  transform: translate(0, -50%) rotate(45deg) !important;
}
